import React from 'react';
import { useLocation } from 'react-router-dom';
import Pdf from './Pdf';

const PdfViewer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pdfPath = queryParams.get('pdf');
  const source = queryParams.get('source');
  
  return <Pdf pdfFileURL={pdfPath} source={source} />;
};

export default PdfViewer;
