import React, { memo, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { VariableSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { downloadFile } from '../../../Services/FileService';
import { BlobFileSourceName } from '../../../Constants/Constants';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import styles from './PdfDocument.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const renderPage = memo(
  ({ index, style }) => (
    <div style={style}>
      <Page
        pageNumber={index + 1}
        scale={1}
        className={styles['react-pdf__Page']}
      />
    </div>
  ),
  areEqual
);

const PdfDocument = forwardRef(({ pdfFileURL, source, onDocumentLoad }, ref) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageViews, setPageViews] = useState(new Map());
  const listRef = useRef(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const pageTopDownMargin = 10;

  const getPageHeight = (pageIndex) => {
    // a page view is an array with 4 elements: [minWidth, minHeight, maxWidth, maxHeight]
    const view = pageViews.get(pageIndex);
    const pageHeight = view[3] - view[1];
    return pageHeight + pageTopDownMargin;
  }

  useImperativeHandle(ref, () => ({
    scrollToPage
  }));

  const documentLoadHandler = async (pdf) => {
    setNumberOfPages(pdf.numPages);
    const newPageViews = new Map();
    const pageSizeArray= await Promise.all(
      Array.from({ length: pdf.numPages }, (_, i) => pdf.getPage(i+1).then((page) => ({pageIndex: i, pageView: page.view})))
    )

    pageSizeArray.forEach(({pageIndex, pageView}) => {
      newPageViews.set(pageIndex, pageView);
    });
    setPageViews(newPageViews);
    await onDocumentLoad(pdf, newPageViews);
  };

  const scrollToPage = (pageIndex) => {
    listRef.current.scrollToItem(pageIndex, 'start');
  }

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        if(source === BlobFileSourceName) 
          {        
            setIsLoading(false);
            return;
          }
        setIsLoading(true);

        let fileResponse = await downloadFile(pdfFileURL, (progressEvent) => {});

        const pdfBlob = new Blob([fileResponse.data], { type: "application/pdf" });

        if (!pdfBlob.size) {
          throw new Error("Received empty PDF file.");
        }

        setPdfFile(URL.createObjectURL(pdfBlob));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPDF();
  },[pdfFileURL]);

  return (
    <>
    {isLoading ? (<div className={styles.documentLoaderContainer}><CircularProgress /></div>) :(
    <Document
      file={ source === BlobFileSourceName ? pdfFileURL : pdfFile }
      onLoadSuccess={documentLoadHandler}
      onItemClick={(e) => scrollToPage(e.pageIndex)}
      className={styles.documentContainer}
      loading={<CircularProgress />}
    >
      { pageViews && pageViews.size &&  
      <AutoSizer>
        {({ height: pagesHeight, width: pagesWidth }) => (
          <List
            ref={listRef}
            // we are subtracting 1 from height and width to avoid exceeding actual height and width which will
            // trigger flickering
            height={pagesHeight - 1}
            width={pagesWidth - 1}
            itemCount={numberOfPages}
            itemSize={getPageHeight}
          >
            {renderPage}
          </List>
        )}
      </AutoSizer>
      }
    </Document>
    )}
    </>
  );
});

export default memo(PdfDocument);